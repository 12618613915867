.slider-area2 {
    height: 209px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
}

.footer-area.vertical-footer {
    margin-top: -10px;
}
.single-service2 .service-icon {
    background-color: #38B6FF;
    display: inline-table;
    height: 150px;
    vertical-align: middle;
    margin-bottom: 24px;
    text-align: center;
    width: 150px;
    transition: all 0.3s ease 0s;
}
.feature-bg-image{margin-top: 0px;}
.download-area{margin-top: 0px;}
.button-default.button-olive{background-color: #38B6FF;}
.theme-color{color: #38B6FF;}
.button-white:hover{background-color: #38B6FF;}
.tap-top{background-color: #38B6FF;}
.banner-apps .single-app{background: #38B6FF none repeat scroll 0 0}
.single-service .service-icon{background-color:#38B6FF}
.feature .feature-icon{background-color:#38B6FF}
.contact-icon{background-color:#38B6FF}
.contact-inner .input-box .submite-button{background-color:#38B6FF}
/*.main-menu .active a{border-bottom: 5px solid #38B6FF;}
 .main-menu li a:hover{border-bottom: 5px solid #38B6FF;} */
